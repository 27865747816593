
import Vue from '@/vueTyped';
import UniversalImage from '@/components/UniversalImage.vue';
import { Photo } from '@/types';
import { openFilePicker } from '@/util.app';

type AcceptableImage = File | Photo;

export default Vue.extend({
    components: {
        UniversalImage,
    },

    props: {
        value: {
            type: Array as () => AcceptableImage[],
            default: () => [],
        },

        accept: {
            type: String,
            default: '',
        },

        max: {
            type: Number,
            default: Infinity,
        },

        newDesign: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            itemBeingDragged: null as AcceptableImage | null,
            dropIndex: null as number | null,
        };
    },

    methods: {
        identify(item: AcceptableImage): string {
            if (item instanceof File) {
               return `${item.lastModified} ${item.name} ${item.size} ${item.type}`;
            } else {
                return item.id;
            }
        },

        add(incoming: AcceptableImage[], index: number = Infinity) {
            incoming = incoming.filter(incomingFile => {
                return !this.value.some(existingFile => this.identify(existingFile) === this.identify(incomingFile));
            });

            const newValue = Array.from(this.value);
            newValue.splice(index, 0, ...incoming);
            this.emitInput(newValue.slice(0, this.max));
        },

        move(item: AcceptableImage, newIndex: number) {
            const newValue = Array.from(this.value);
            const oldIndex = newValue.indexOf(item);
            newValue.splice(oldIndex, 1);
            if (oldIndex < newIndex) {
                newIndex -= 1;
            }
            newIndex = Math.min(Math.max(0, newIndex), this.value.length - 1);
            newValue.splice(newIndex, 0, item);
            this.emitInput(newValue);
        },

        remove(item: AcceptableImage) {
            const newValue = Array.from(this.value);
            const index = newValue.indexOf(item);
            newValue.splice(index, 1);
            this.emitInput(newValue);
        },

        startDragging(item: AcceptableImage, event: PointerEvent) {
            event.preventDefault();

            this.itemBeingDragged = item;

            const document = (event.target as HTMLElement).ownerDocument;
            document.addEventListener('pointermove', this.handleDrag);
            document.addEventListener('pointerup', this.handleRelease);
            document.addEventListener('keydown', this.handleRelease);
        },

        handleDrag(event: PointerEvent) {
            type DropTarget = HTMLElement & { dataset: { index: string } } | null;

            const document = (event.target as HTMLElement).ownerDocument;
            const topElement = document.elementFromPoint(event.x, event.y);
            const dropTarget = topElement?.closest('.images > [data-index]') as DropTarget;

            if (dropTarget) {
                const rect = dropTarget.getBoundingClientRect();
                const left = event.x < rect.left + rect.width / 2;
                this.dropIndex = parseFloat(dropTarget.dataset.index) + (left ? 0 : 1);
            } else if (this.$el.contains(topElement)) {
                this.dropIndex = this.value.length;
            } else {
                this.dropIndex = null;
            }
        },

        handleRelease(event: PointerEvent | KeyboardEvent) {
            let cancel = false;

            if (event.type === 'keydown') {
                if ((event as KeyboardEvent).key === 'Escape') {
                    cancel = true;
                } else {
                    return;
                }
            }

            const document = (event.target as HTMLElement).ownerDocument;
            document.removeEventListener('pointermove', this.handleDrag);
            document.removeEventListener('pointerup', this.handleRelease);
            document.removeEventListener('keydown', this.handleRelease);

            if (!cancel && this.itemBeingDragged && this.dropIndex !== null) {
                this.move(this.itemBeingDragged, this.dropIndex);
            }

            this.itemBeingDragged = null;
            this.dropIndex = null;
        },

        async handleAddClick() {
            const selectedFiles = await openFilePicker(this.accept, true, true);
            this.$emit('input', [...this.value, ...selectedFiles]);
        },

        handleFileDrop(event: DragEvent) {
            event.preventDefault();

            const files = event.dataTransfer?.files ?? [];
            const imageFiles = Array.from(files).filter(file => file.type.startsWith('image/'));

            if (imageFiles.length !== 0 && this.dropIndex !== null) {
                this.add(imageFiles, this.dropIndex);
            }

            this.dropIndex = null;
        },

        async emitInput(newValue: AcceptableImage[]) {
            const focusedElement = this.$el.ownerDocument.activeElement;
            this.$emit('input', newValue);
            await this.$nextTick();
            (focusedElement as HTMLElement | null)?.focus();
        },
    },
});
